import './App.scss';
import banner from './banner.png';
import info_icon from './icon_info.svg';
import blue_icon from './blue_icon.png';
import { useState } from 'react';

function App() {
  const [type, setType] = useState('');
  const [popClass, setPopClass] = useState('popup');
  const [underMessage, setUnderMessage] = useState(<></>);
  const [vals, setVals] = useState({
    dosage_for_patient: 0,
    bottles_count: 0,
    value_needed: 0,
    all_value_infusions: 0,
    stage1: 0,
    stage2: 0,
    stage3: 0,
    stage4: 0,
    stage5: 0,
  });
  const pdfLink = 'ava_calculator.pdf';

  const changeType = (tp) => {
    document.querySelectorAll('.container').forEach((itm, i) => itm.classList.remove('red'))
    setUnderMessage(<></>)
    if(tp === 'БППН'){
      document.getElementById('bppn_type').checked = true
      document.getElementById('mbp_type').checked = false
      setUnderMessage(<div><b>Рекомендуемая дозировка при БППН:</b> 20 мг/кг 1 раз в две недели</div>);
    }
    if(tp === 'МБП'){
      document.getElementById('bppn_type').checked = false
      document.getElementById('mbp_type').checked = true
      setUnderMessage(<div><b>Рекомендуемая дозировка при БППН:</b> 40 мг/кг 1 раз в две недели</div>);
    }
    setType(tp)
    setVals({
      dosage_for_patient: 0,
      bottles_count: 0,
      value_needed: 0,
      all_value_infusions: 0,
      stage1: 0,
      stage2: 0,
      stage3: 0,
      stage4: 0,
      stage5: 0,
    });
  }

  const calculate = () => {
    const  weight = Math.round(document.getElementById('weight').value.replace(/,/g, '.'));
    if(type === ''){
      setUnderMessage(<div className='err'>Укажите форму болезни Помпе</div>)
      document.querySelectorAll('.container').forEach((itm, i) => itm.classList.add('red'))
    }else{
      if(isNaN(weight) || weight <  1.25 || weight > 200){
        setUnderMessage(<div className='err'>Укажите верное значение веса пациента. Итоговая концентрация препарата авалглюкозидаза альфа в растворе для инфузии должна составлять 0,5–4 мг/мл</div>)
        document.getElementById('weight').classList.add('red');
      }else{
        document.getElementById('weight').classList.remove('red');
        let dosage_for_patient = 0;
        let all_value_infusions = 0;
        let stage1 = 0;
        let stage2 = 0;
        let stage3 = 0;
        let stage4 = 0;
        let stage5 = 0;
        if(type === 'БППН'){
          dosage_for_patient = weight * 20;
          setUnderMessage(<div><b>Рекомендуемая дозировка при БППН:</b> 20 мг/кг 1 раз в две недели</div>);
          if(weight > 1.25 && weight <= 10 ){
            all_value_infusions = 50;
            stage1 = 3;
            stage2 = 8;
            stage3 = 13;
            stage4 = 18;
          }
          if(weight > 10 && weight <= 20 ){
            all_value_infusions = 100;
            stage1 = 5;
            stage2 = 15;
            stage3 = 25;
            stage4 = 35;
          }
          if(weight > 20 && weight <= 30 ){
            all_value_infusions = 150;
            stage1 = 8;
            stage2 = 23;
            stage3 = 38;
            stage4 = 53;
          }
          if(weight > 30 && weight <= 35 ){
            all_value_infusions = 200;
            stage1 = 10;
            stage2 = 30;
            stage3 = 50;
            stage4 = 70;
          }
          if(weight > 35 && weight <= 50 ){
            all_value_infusions = 250;
            stage1 = 13;
            stage2 = 38;
            stage3 = 63;
            stage4 = 88;
          }
          if(weight > 50 && weight <= 60 ){
            all_value_infusions = 300;
            stage1 = 15;
            stage2 = 45;
            stage3 = 75;
            stage4 = 105;
          }
          if(weight > 60 && weight <= 100 ){
            all_value_infusions = 500;
            stage1 = 25;
            stage2 = 75;
            stage3 = 125;
            stage4 = 175;
          }
          if(weight > 100 && weight <= 120 ){
            all_value_infusions = 600;
            stage1 = 30;
            stage2 = 90;
            stage3 = 150;
            stage4 = 210;
          }
          if(weight > 120 && weight <= 140 ){
            all_value_infusions = 700;
            stage1 = 35;
            stage2 = 105;
            stage3 = 175;
            stage4 = 245;
          }
          if(weight > 140 && weight <= 160 ){
            all_value_infusions = 800;
            stage1 = 40;
            stage2 = 120;
            stage3 = 200;
            stage4 = 280;
          }
          if(weight > 160 && weight <= 180 ){
            all_value_infusions = 900;
            stage1 = 45;
            stage2 = 135;
            stage3 = 225;
            stage4 = 315;
          }
          if(weight > 180 && weight <= 200 ){
            all_value_infusions = 1000;
            stage1 = 50;
            stage2 = 150;
            stage3 = 250;
            stage4 = 350;
          }
        }
        if(type === 'МБП'){
          dosage_for_patient = weight * 40;
          setUnderMessage(<div><b>Рекомендуемая дозировка при БППН:</b> 40 мг/кг 1 раз в две недели</div>);
          if(weight > 1.25 && weight <= 5 ){
            all_value_infusions = 50;
            stage1 = 1;
            stage2 = 4;
            stage3 = 8;
            stage4 = 10;
            stage5 = 12;
          }
          if(weight > 5 && weight <= 10 ){
            all_value_infusions = 100;
            stage1 = 3;
            stage2 = 8;
            stage3 = 15;
            stage4 = 20;
            stage5 = 25;
          }
          if(weight > 10 && weight <= 20 ){
            all_value_infusions = 200;
            stage1 = 5;
            stage2 = 15;
            stage3 = 30;
            stage4 = 40;
            stage5 = 50;
          }
          if(weight > 20 && weight <= 30 ){
            all_value_infusions = 300;
            stage1 = 8;
            stage2 = 23;
            stage3 = 45;
            stage4 = 60;
            stage5 = 75;
          }
          if(weight > 30 && weight <= 35 ){
            all_value_infusions = 400;
            stage1 = 10;
            stage2 = 30;
            stage3 = 60;
            stage4 = 80;
            stage5 = 100;
          }
          if(weight > 35 && weight <= 50 ){
            all_value_infusions = 500;
            stage1 = 13;
            stage2 = 38;
            stage3 = 75;
            stage4 = 100;
            stage5 = 125;
          }
          if(weight > 50 && weight <= 60 ){
            all_value_infusions = 600;
            stage1 = 15;
            stage2 = 45;
            stage3 = 90;
            stage4 = 120;
            stage5 = 150;
          }
          if(weight > 60 && weight <= 100 ){
            all_value_infusions = 1000;
            stage1 = 25;
            stage2 = 75;
            stage3 = 150;
            stage4 = 200;
            stage5 = 250;
          }
          if(weight > 100 && weight <= 120 ){
            all_value_infusions = 1200;
            stage1 = 30;
            stage2 = 90;
            stage3 = 180;
            stage4 = 240;
            stage5 = 300;
          }
          if(weight > 120 && weight <= 140 ){
            all_value_infusions = 1400;
            stage1 = 35;
            stage2 = 105;
            stage3 = 210;
            stage4 = 280;
            stage5 = 350;
          }
          if(weight > 140 && weight <= 160 ){
            all_value_infusions = 1600;
            stage1 = 40;
            stage2 = 120;
            stage3 = 240;
            stage4 = 320;
            stage5 = 400;
          }
          if(weight > 160 && weight <= 180 ){
            all_value_infusions = 1800;
            stage1 = 45;
            stage2 = 135;
            stage3 = 270;
            stage4 = 360;
            stage5 = 450;
          }
          if(weight > 180 && weight <= 200 ){
            all_value_infusions = 2000;
            stage1 = 50;
            stage2 = 150;
            stage3 = 300;
            stage4 = 400;
            stage5 = 500;
          }
        }
        console.log(dosage_for_patient)
        const bottles_count = Math.ceil( dosage_for_patient / 100 );
        const value_needed = dosage_for_patient / 10;
        setVals({
          dosage_for_patient: dosage_for_patient,
          bottles_count: bottles_count,
          value_needed: value_needed,
          all_value_infusions: all_value_infusions,
          stage1: stage1,
          stage2: stage2,
          stage3: stage3,
          stage4: stage4,
          stage5: stage5,
        });
      }
    }
    console.log(weight)
  }

  return (
    <div className="App">
      <div className='header_ttl'>Калькулятор расчета дозировки препарата авалглюкозидаза альфа для пациентов с болезнью Помпе</div>
      <div className='banner'><img src={banner} /></div>
      <a className='downloadPDF mobile' href={pdfLink}>
        <div>
          <div>Документы</div>
          <div>Скачать пдф-версию калькулятора</div>
        </div>
      </a>
      <div className='calc_area'>
        <div>
          <div>
            <div>Калькулятор расчета дозы позволяет определить оптимальную рекомендованную дозу <b>авалглюкозидазы альфа</b>, а также скорость дозирования и инфузии.</div>
            <div>Выберите форму болезни Помпе</div>
            <div>
              <label className="container">
                <input id="bppn_type" type="checkbox" onChange={() => changeType('БППН')} />
                <span className="checkmark"></span>
                БППН
              </label>

              <label className="container">
                <input id="mbp_type" type="checkbox" onChange={() => changeType('МБП')} />
                <span className="checkmark"></span>
                МБП
              </label>
            </div>
            <div>Введите вес пациента, кг</div>
            <div>
              <div><input id="weight" type="text" /></div>
              <div><button type="button" onClick={calculate}>Рассчитать</button></div>
            </div>
            <div id="message">
              {underMessage}
              
            </div>
            <div className='sqrs'>
              <div>
                <div className='ttl'>Дозировка для пациентов</div>
                <div className='descritiomn'></div>
                <div className='value'><span>{vals.dosage_for_patient}</span><span>мг</span></div>
              </div>
              <div>
                <div className='ttl'>Количество флаконов</div>
                <div className='descritiomn'>(Содержимое каждого флакона разводят 10 мл стерильной воды)</div>
                <div className='value'><span>{vals.bottles_count}</span></div>
              </div>
              <div>
                <div className='ttl'>Необходимый объем</div>
                <div className='descritiomn'>(Необходимый объем восстановленного препарата авалглюкозидаза альфа)</div>
                <div className='value'><span>{vals.value_needed}</span><span>мл</span></div>
              </div>
              <div>
                <div className='ttl'>Общий объем инфузии</div>
                <div className='descritiomn'>(Восстановленный раствор медленно добавьте в 5% водный раствор глюкозы)</div>
                <div className='value'><span>{vals.all_value_infusions}</span><span>мл</span></div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a className='downloadPDF desctop' href={pdfLink}>
            <div>
              <div>Документы</div>
              <div>Скачать пдф-версию калькулятора</div>
            </div>
          </a>
          <div className='rightVals'>
            <div>Введение препарата <img src={info_icon} onClick={() => setPopClass('popup is-on')} /></div>
            <div>Рекомендуемая скорость инфузии препарата авалглюкозидаза альфа</div>
            <span>
              <div>Этап 1</div>
              <div><span>{vals.stage1}</span><span>&nbsp;мл/ч</span></div>
            </span>
            <div className='legend'>1 мг/кг/час; 30 мин</div>
            <span>
              <div>Этап 2</div>
              <div><span>{vals.stage2}</span><span>&nbsp;мл/ч</span></div>
            </span>
            <div className='legend'>3 мг/кг/час; через 30 мин</div>
            <span>
              <div>Этап 3</div>
              <div><span>{vals.stage3}</span><span>&nbsp;мл/ч</span></div>
            </span>
            <div className='legend'>{type === 'МБП' ? '6 мг/кг/час; через 60 мин' : '5 мг/кг/час*; через 60 мин'} </div>
            <span>
              <div>Этап 4</div>
              <div><span>{vals.stage4}</span><span>&nbsp;мл/ч</span></div>
            </span>
            <div className='legend'>{type === 'МБП' ? '8 мг/кг/час; через 90 мин' : '7 мг/кг/час*; через 90 мин**'}</div>
            <span className={type === 'МБП' ? '' : 'hidden'}>
              <div>Этап 5</div>
              <div><span>{vals.stage5}</span><span>&nbsp;мл/ч</span></div>
            </span>
            <div className={type === 'МБП' ? 'legend' : 'legend hidden'}>10 мг/кг/час**; через 120 мин*<span>(Максимальная скорость инфузии)</span></div>
            {type === 'МБП' ?
              <ul>
                <li>* Через 120 минут и до завершения инфузии.</li>
                <li>** Для пациентов с МБП с массой тела 1,25–5 кг максимальная скорость инфузии составляет 9,6 мг/кг/час.</li>
              </ul>
            : 
              <ul>
                <li>* Для пациентов с БППН с массой тела 1,25–5 кг максимальная скорость инфузии составляет 4,8 мг/кг/ч.</li>
                <li>** Через 90 минут и до завершения инфузии.</li>
              </ul>
            }
            
          </div>
        </div>
      </div>
      <div className='calc_area'>
        <div>
          <span className='bot_legend_1'>
              При расчете дозы препарата авалглюкозидаза альфа с помощью калькулятора используются рекомендации, указанные в общей характеристике лекарственного препарата: 20 мг/кг (БППН) и 40 мг/кг (МБП) один раз в две недели.<br/>
              <br/>
              Перед назначением препарата авалглюкозидаза альфа необходимо ознакомиться с полным текстом общей характеристики лекарственного препарата.
            </span>
            <span className='bot_legend_2'>
              <img src={blue_icon} />
              <div>
                Данный лекарственный препарат подлежит дополнительному мониторингу. Такая процедура позволяет быстро выявлять новые данные по безопасности.<br/>
                <b>Работников здравоохранения просим сообщать о любых подозрениях на развитие нежелательных реакций.</b>
              </div>
            </span>
            <span className='links'>
              <div>Источники</div>
              <div className='r'>
                <span>1.</span>
                <span>Общая характеристика лекарственного препарата Нексвиазайм<sup>®</sup><br/>(РУ № ЛП-№(002078)-(РГ-RU)).</span>
              </div>
            </span>
        </div>
        <div>
          <div className='shorts'>
            <div>Сокращения</div>
            <div>БППН — болезнь Помпе с поздним началом;<br/>МБП — младенческая форма болезни Помпе.</div>
          </div>
        </div>
      </div>
      
      <div className={popClass}>
        <div>
          При отсутствии признаков инфузионных реакций (ИР) скорость инфузии следует постепенно увеличивать каждые 30 минут. Общее время инфузии для пациентов с БППН/МБП составляет примерно 4–5 часов.
          <div className='close' onClick={() => setPopClass('popup')}></div>
        </div>
      </div>
    </div>
  );
}

export default App;
